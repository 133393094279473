<template>
  <div >
    <el-pagination
        :class="false ? 'pagination__day' : 'pagination__night'"
      :page-sizes="[100, 200, 300, 400, 500, 600, 700, 800, 900, 1000]"
      :page-size="1000"
      layout="sizes, prev, pager, next"
      :total="10000"
    >
    </el-pagination>
  </div>
</template>
<script>
import {mapGetters} from 'vuex'
export default {
    data: () => ({
        mode: false
    })
}
</script>

